import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';

class BoxIntroduction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expand: false
    };
  }

  render() {
    return (
      <section className="bg-white">
        <div className="container">
          {/* <h2><Trans i18nKey="introbox_title">Medienübergreifende Recherchen</Trans></h2> */}
          <h1><Trans i18nKey="main-title" /></h1>
          <p>
            <Trans i18nKey="introbox_text_p1">Jedes Jahr zahlen Pharma-Firmen in der Schweiz deutlich über 100 Millionen Franken an Ärzte, Organisationen und Spitäler. Mit diesem Geld laden die Pharmaunternehmen Ärzte zu Kongressen ein, bezahlen Berater-Honorare, kommen für Reisen, Übernachtungen oder Essen auf. Manche Gelder fliessen als Spenden an Spitäler und Vereine oder als Sponsorings an Apotheken. Mit anderen wiederum werden Honorare für Vorträge bezahlt. Jedes Jahr veröffentlichen die Pharma-Firmen im Rahmen des Pharma-Kooperations-Kodex (PKK) diese Zahlungen. Jedes Unternehmen für sich, teils unübersichtlich und schlecht strukturiert und durchsuchbar.</Trans>
          </p>

          <p>
            <Trans i18nKey="introbox_text_p2">Ein Team von Journalisten der Medien Beobachter, Blick, Handelszeitung und Le Temps hat im Rahmen  des Ringier Axel Springer Research Network die veröffentlichten Daten jeder Pharmafirma aufbereitet, zusammengeführt und ausgewertet.</Trans>
          </p>

          {this.state.expand &&
            <div>
              <p>
                <Trans i18nKey="introbox_network" />
              </p>
            </div>
          }

          <span role='link' className='link_bold' onClick={() => this.setState({ expand: !this.state.expand })}>{this.state.expand ? this.props.t('introbox_readless') : this.props.t('introbox_readmore')}</span>

          <div className="partner_container">
            <div className="partner"><a href="https://www.blick.ch" target="_blank" rel="noopener noreferrer"><img src="/img/blick.png" alt="Logo Blick" /></a></div>
            <div className="partner"><a href="https://www.beobachter.ch/" target="_blank" rel="noopener noreferrer"><img src="/img/beobachter.png" alt="Logo Beobachter" /></a></div>
            <div className="partner"><a href="https://www.handelszeitung.ch/" target="_blank" rel="noopener noreferrer"><img src="/img/handelszeitung.png" alt="Logo Handelszeitung" /></a></div>
            {/* <div className="partner"><a href="https://www.letemps.ch/" target="_blank" rel="noopener noreferrer"><img src="/img/letemps.png" alt="Logo Le Temps" /></a></div> */}
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(BoxIntroduction);