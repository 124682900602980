import React, { Component, Suspense } from 'react';
import { useTranslation, Trans, Translation, withTranslation } from 'react-i18next';
import history from "./helpers/history.js";
import i18n from 'i18next';

import './app.css';
import { Router, Route } from "react-router-dom";
import { Link } from "react-router-dom";

import * as Scroll from 'react-scroll';

import ApiConnector from './api/apiconnector.js';

import Helpers from './helpers/helpers';
import Loading from './atoms/loading.js';
//import Meta from "./atoms/meta.js";

import SearchForm from './atoms/searchform.js';
import SearchResult from './search/searchresults.js';

import VisitLog from './atoms/visitlog.js';
import ErrorMessage from './atoms/errormessage.js';
import ArticleList from './atoms/articlelist.js';
import BoxIntroduction from './atoms/boxintroduction.js';

import Recipient from './recipient/recipient.js';
import Pharma from './pharma/pharma.js';


class DatabaseStandalone extends Component {

  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.state = {
      searchValue: '',
      showNoResults: false,
      hasError: false,
      result_recipients: [],
      result_pharmas: [],
      lastViewsLastFetched: Date.now(),
      locale: Helpers.getShortLanguage(i18n.languages[0])
    };

    //Init Timer
    this.searchTimer = null;

    //Init ApiConnector
    this.apiConnector = new ApiConnector((e) => {
      this.setState({
        hasError: true,
        errorMessage: e
      });
    });

    this.recipientOrPharmaChanged = this.recipientOrPharmaChanged.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.performeSearch = this.performeSearch.bind(this);

    // Create Ref to know, where to scroll at
    this.refSearchbox = React.createRef();

    // i18n.on('languageChanged', function (lng) {
    //   console.log(lng);
    //   // @TODO - call onLanguageChanged(lng)? -- I don't know how to do the binding
    // });
  }

  onLanguageChanged(lng) {
    this.setState({ locale: lng });
  }

  performeSearch(searchTerm) {
    if (searchTerm !== "") {
      history.push(`/search/${searchTerm}`)
    }
  }

  track()
  {
    const path = window.location.pathname;
    //console.log("Track", path);
    window.gtag('config', 'UA-132894010-2', {'page_path': path});
  }

  onSearch(event) {
    this.setState({
      searchValue: event.target.value,
      showNoResults: false
    });

    //Clear Timer
    clearTimeout(this.searchTimer);

    //Run Request
    if (event.target.value.length > 0) {
      let searchTerm = event.target.value;
      //Start a timer on key down
      this.searchTimer = setTimeout(() => {
        this.onSearchInternal(searchTerm);
      }, 1000);
    }
  }

  recipientOrPharmaChanged() {

    this.track();

    this.setState({
      lastViewsLastFetched: Date.now()
    });
    this.scrollTo();
  }

  scrollTo() {
    if(this.refSearchbox.current)
    {
      const top = this.refSearchbox.current.getBoundingClientRect().top + window.scrollY;
      Scroll.animateScroll.scrollTo(top);
    }
  }

  componentDidMount() {
    //this.scrollTo();
  }

  render() {
    return (
      <Router history={history}>
        <div className="App">

{/*           
          <Translation>
            {
              t => <Meta title={t('social-title')} description={t('description')} locale={this.state.locale} />
            }
          </Translation> */}

          <section className="bg" />

          <section className="intro">
            <div className="containerX">
              <div className="logo_container">
                <Translation>
                  {
                    t => <Link to='/'><img src={`/img/localized/${t("logo")}`} className="logo" alt="" /></Link>
                  }
                </Translation>
              </div>

              {process.env.REACT_APP_API_URL.includes('stg') &&
                <h2 style={{ 'color': 'red' }}>Testumgebung</h2>
              }

              {/* <h1><Trans i18nKey="main-title" /></h1> */}
            </div>
          </section>

          <section className='section_language'>
            <Translation>
              {
                (t, { i18n }) => (
                  <div>
                    <button onClick={() => i18n.changeLanguage('de')}>de</button>
                    <button onClick={() => i18n.changeLanguage('fr')}>fr</button>
                  </div>
                )
              }
            </Translation>
          </section>

          <BoxIntroduction />

          {this.state.hasError ? (
            <ErrorMessage message={this.state.errorMessage} />
            ) : (
              <SearchForm executeSearch={this.performeSearch} />
          )}

          <section className="bg-light results" ref={this.refSearchbox} >
            <div className="container">

              <Route path="/" exact={true} render={(props => {
                this.track();
                return null;
              })} />

              <Route path="/:lng?/search/:searchTerm" render={(props => {
                return <SearchResult searchTerm={props.match.params.searchTerm} apiConnector={this.apiConnector} />
              })} />

              <Route path="/:lng?/pharma/:pharmaId-:pharmaName.html" render={(props => {
                return <Pharma pharmaId={props.match.params.pharmaId} onRecipientChanged={this.recipientOrPharmaChanged} apiConnector={this.apiConnector} />
              })} />

              <Route path="/:lng?/recipient/:recipientId-:recipientName.html" render={(props => {
                return <Recipient recipientId={props.match.params.recipientId} onRecipientChanged={this.recipientOrPharmaChanged} apiConnector={this.apiConnector} />
              })} />

            </div>
          </section>

          {!this.state.hasError &&
            <section className="visits">
              <div className="container">
                <VisitLog lastFetch={this.state.lastViewsLastFetched} apiConnector={this.apiConnector} locale={this.state.locale} />
              </div>
            </section>
          }

          <section className="bg-dark">
            <ArticleList />
          </section>

          <section className="bg-grey">
            <div className="container">
              <footer>
                <p><b><Trans i18nKey="footer_research_network" /></b></p>

                <p>
                  <b><Trans i18nKey="footer_participants" /></b><br />
                  Seraina Gross (Handelszeitung), Michael Heim (Handelszeitung), Otto Hostettler (Beobachter), Simon Huwiler, Michel Jeanneret (BLICK Suisse romande), Andrea Klaiber (Beobachter), Ulrich Rotzinger (BLICK), Anne Seeger (Beobachter)
                </p>

                <p>
                  <b><Trans i18nKey="footer_participants_history" /></b><br />
                  Danilo Balocco (BLICK), Evdoxos Bekas (BLICK), Vinzenz Greiner (BLICK), Janine Gygax (BLICK), Sylvie Logean (Le Temps), Marie Maurisse (Le Temps), Spiridon Petridis (BLICK) und Paul Ronga (Le Temps)
                </p>

                <p>
                  <b><Trans i18nKey="footer_development" /></b><br />
                  Simon Huwiler, Storytelling Blick-Gruppe
                </p>

                <p>
                  <b><Trans i18nKey="footer_thanks_title" /></b><br />
                  <Trans i18nKey="footer_thanks" />
                </p>

                <p>
                  <Trans i18nKey="footer_award" />
                </p>

                <p>
                  <a href='https://www.ringieraxelspringer.ch/de/datenschutzbestimmungen-0' target='_blank' rel="noopener noreferrer"><Trans i18nKey="footer_privacy" /></a>
                </p>

                <p>
                  <b><Trans i18nKey="footer_contact" /></b><br />
                  Ringier Axel Springer Schweiz<br />
                  Flurstrasse 55<br />
                  Postfach<br />
                  8021 Zürich<br />
                  Schweiz<br />
                  <a href="mailto:info@pharmagelder.ch" target='_blank' rel="noopener noreferrer">info@pharmagelder.ch</a>
                </p>

                <div className='impressum_logo'>
                  <a href="https://www.ringier.ch" target="_blank" rel="noopener noreferrer"><img src='/img/ringier.png' alt='Logo Ringier AG' /></a>
                  <a href="https://www.ringieraxelspringer.ch" target="_blank" rel="noopener noreferrer"><img src='/img/rasch.png' alt='Logo Ringier Axel Springer Schweiz' /></a>
                </div>

              </footer>
            </div>
          </section>

        </div>
      </Router >
    );
  }
}

// loading component for suspence fallback
const Loader = () => (
  <div className="App">
    <Loading />
  </div>
);

function Page() {
  useTranslation();
  const DatabaseStandaloneHOC = withTranslation()(DatabaseStandalone);
  return <DatabaseStandaloneHOC />
}

// export default DatabaseStandalone;
// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}
